<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import axios from "axios";
import {dateUtil} from "@/helpers/date-util";
import {errorCatcher} from "@/helpers/error-catcher";

import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";

export default {

  components: {
    CustomCardSubtitle,
    Layout,
    PageHeader
  },

  data() {
    return {
      submitted: false,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        isBusy: false,
        inputPage: "",

        items: []
      },

      depot: {
        name: "",
        warehouseId: ""
      },

      visibleModal: false
    };
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('core.depots.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        {key: "name", label: this.$t('message.name')},
        {key: "createdAt", label: this.$t('table.created-at'), formatter: dateUtil.asDateTime},
        {key: "updatedAt", label: "Ostatnia aktualizacja", formatter: dateUtil.asDateTime},
        {key: "action", slot: true, label: this.$t('table.actions')}
      ]
    },

    async loadDepots() {
      let page = this.table.currentPage - 1;
      if (page > 0) {
        page = this.table.currentPage * this.table.perPage - this.table.perPage;
      }

      const { data } = await axios.get(`/depot/pagination`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          page: page,
          size: this.table.perPage
        }
      });

      this.table.items = data.depots
      this.table.totalRows = data.count;
      this.table.rows = data.count;

      return this.table.items;
    },

    openModal() {
      this.visibleModal = true
    },

    hideModal() {
      this.visibleModal = false
      this.depot = {
        name: "",
        warehouseId: ""
      }
    },

    editDepot(depot) {
      this.depot = Object.assign({}, depot)
      this.openModal()
    },

    createOrEditDepot() {
      const json = JSON.stringify(this.depot)

      axios.put(`/depot`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        this.$bvToast.toast(this.$t(this.depot.id ? "Edytowano hurtownie" : "Utworzono nową hurtownie"), {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        this.$refs.table.refresh()
        this.hideModal()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    }

  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('core.depots.title')" :items="getItems()"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <custom-card-subtitle title="Akcje"/>

              <div class="col-xl-12">
                <b-button variant="success" @click="openModal">Utwórz nową hurtownie</b-button>
              </div>
            </div>

            <ecat-table
                ref="table"
                :table="table"
                :fields="getFields()"
                :items="loadDepots"
                :pagination-top="true"
            >
              <template v-slot:action="{ item }">
                <div class="button-items">
                  <b-button variant="primary" class="btn-sm" @click="editDepot(item)">Edytuj</b-button>
                  <a :href="`/dashboard/admin/depot/products/${item.id}`" class="btn btn-success btn-sm">Produkty</a>
                  <a :href="`/dashboard/admin/depot/sections/${item.id}`" class="btn btn-info btn-sm">Magazyny</a>
                </div>
              </template>
            </ecat-table>
          </div>
        </div>
      </div>
    </div>

    <b-modal
        id="modal-1"
        v-model="visibleModal"
        hide-footer
        :title="depot.id ? 'Edytowanie hurtowni' : 'Tworzenie nowej hurtowni'"
        title-class="font-18"
        @esc="hideModal"
        @hide="hideModal">
      <div class="form-group">
        <label>Nazwa hurtowni</label>
        <input v-model="depot.name" type="text" class="form-control"/>
      </div>

      <div class="form-group">
        <ecat-multiselect
            @change="value => depot.warehouseId = value"
            @select="$refs.table.refresh()"
            :set-value="depot.warehouseId"
            save-id="id"
            view-id="name"
            label="Hurtownia"
            placeholder="Wybierz hurtownie"
            fetch-one-url="/warehouse"
            load-url="/warehouse/list/pagination"
            query-url="/warehouse/by-name"
            param="name"
            :disabled="!!depot.id"
        />
      </div>

      <div class="text-center">
        <b-button class="mx-2" variant="success" @click="createOrEditDepot">
          Zapisz
        </b-button>

        <b-button variant="danger" class="my-3" @click="hideModal">
          {{ $t('message.cancel') }}
        </b-button>
      </div>
    </b-modal>
  </Layout>
</template>